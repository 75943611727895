
import Vue, { VNode } from 'vue';
import { Component } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import HotelViewRoot from '../-index';
import {
  MyHotelLocalNavi,
  MyHotelSection,
  MyHotelSectionPortal,
  MyHotelSectionData,
  MyHotelAdditionalData,
} from './-components';
import { MyHotelInstantPhoto } from './-components/MyHotelInstantPhoto';
import { AvailableLanguage } from '~/schemes';

import {
  widthedStringLength,
  loadImageSize,
  normalizedGFRedirectLanguage,
} from '~/helpers';
import {
  HHtmlInjector,
  HKeyVisual,
  HPhotogalleryBtn,
  HSpriteAnimation,
} from '~/components';

const PORTAL_MATCH_RE = /<!--\s+portal:start:([a-zA-Z0-9-_]+)(\s+class="([\sa-zA-Z0-9-_]*)")?\s+-->([\s\S]*?)<!--\s+portal:end:([a-zA-Z0-9-_]+)\s+-->/;
const PORTALS_MATCH_RE = new RegExp(PORTAL_MATCH_RE.source, 'g');

/**
 * リダイレクト確認ダイアログの文言
 * @memo i18nを使わなかった経緯 https://github.com/HoshinoResort/global-format-pre/pull/1134#discussion_r1721101037
 */
const text = {
  ja: {
    guide: '日本語ページを表示しますか？',
    ok: 'はい',
    cancel: 'いいえ',
  },
  en: {
    guide: 'Would you prefer the English version?',
    ok: 'OK',
    cancel: 'CANCEL',
  },
  ko: {
    guide: '한국어 버전 페이지를 보고 싶으신가요?',
    ok: 'OK',
    cancel: '캔슬',
  },
  zh_tw: {
    guide: '您希望查看中文版頁面嗎？',
    ok: 'OK',
    cancel: '取消',
  },
  zh_cn: {
    guide: '您希望查看中文版吗？',
    ok: 'OK',
    cancel: '取消',
  },
};

/**
 * 下層導線設定が関係するセクションIDのリスト
 *
 * * このIDにマッチするものはトップページバナーで導線をコントロールするようになったので、ここでは強制除外する
 */
const FLOW_LINE_SETTINGS_CONTENTS = ['dining', 'todo', 'activities'];

@Component<MyView>({
  inject: ['hotelViewRoot'],

  asyncData({ $env, $language, $cookies }) {
    const USE_MOTION_LOGO_COOKIE_NAME = 'H-USED-HOTEL-MOTION-LOGO';
    const motionLogoIsUsed =
      $cookies.get(USE_MOTION_LOGO_COOKIE_NAME) === 'used';
    const USE_MOTION_LOGO = $language.current === 'ja' && !motionLogoIsUsed;
    if (USE_MOTION_LOGO) {
      $cookies.set(USE_MOTION_LOGO_COOKIE_NAME, 'used');
    }

    return {
      USE_MOTION_LOGO,
      animLogoEnded: !USE_MOTION_LOGO,
      headLogoLoaded: USE_MOTION_LOGO,
    };
  },
  head() {
    const { preloadImages } = this;
    return {
      link: preloadImages.map((image) => ({
        hid: image,
        rel: 'preload',
        href: image,
        as: 'image',
      })),
    };
  },
  // 施設Slugを監視
  watch: {
    hotelSlug: {
      handler() {
        if (process.server) return;
        // 施設Slug変更時にアニメーションを開始する
        this.$nextTick(this.dispatchIntroAnimation);
      },
      immediate: true,
    },
  },
  mounted() {
    const browserLanguage = normalizedGFRedirectLanguage(navigator.language);

    if (
      !this.$language.getCookie() &&
      this.$language.current !== browserLanguage
    ) {
      this.confirmLanguageRedirect(browserLanguage);
    }

    if (!this.USE_MOTION_LOGO) {
      loadImageSize(this.headLogoUrl)
        .then(() => {
          this.headLogoLoaded = true;
        })
        .catch(() => {
          // ロード失敗してもとりあえず読み込み済みフラグつけとく
          this.headLogoLoaded = true;
        });
    }

    // アニメーションの終了を取得
    const element = document.querySelector('.my-bg--first-view');
    if (!element) return;
    element.addEventListener('animationend', () => {
      this.topHoshinoyaDuringAnimation = false;
    });
  },
  beforeDestroy() {
    this.killIntroAnimationTimeline();
    // イベントリスナー削除
    const element = document.querySelector('.my-bg--first-view');
    if (!element) return;
    element.removeEventListener('animationend', () => {
      this.topHoshinoyaDuringAnimation = false;
    });
  },
  render() {
    const {
      USE_MOTION_LOGO,
      isKai,
      isHoshinoya,
      isLucy,
      hotel,
      brand,
      needPauseAnimations,
      logoAppends,
      sections,
    } = this;
    const { galleries } = hotel;
    const isJA = this.$language.current === 'ja';
    const photogalleryBtn = (
      <HPhotogalleryBtn eventId="mainv" staticClass="my-head__gallery-btn" />
    );
    const separatorIconsIter = this.sectionSeparatorIconsIter();

    // 汎用スタイル
    let childrens: VNode[] = [
      <div staticClass="my-head">
        <HKeyVisual
          staticClass="my-head__key-visual"
          value={hotel.keyVisual}
          paused={needPauseAnimations}
          leadHTML={Boolean(this.leadHTML)}
        />
        <div staticClass="my-head__inner">
          <h1
            staticClass="my-head__logo"
            class={{
              'my-head__logo--has-appends': !!logoAppends,
            }}>
            {/* 界の場合 */}
            {isKai && this.headLogoUrl && (
              <span staticClass="my-head__anim-logo">
                <HSpriteAnimation
                  staticClass="my-head__anim-logo__node"
                  src={this.kaiAnimLogo}
                  width={140}
                  height={140}
                  fps={24}
                  fallback={this.$res.img('/brands/kai/logo-anim-fallback.png')}
                  forceFallback={!USE_MOTION_LOGO}
                  onAnimationend={() => {
                    this.animLogoEnded = true;
                  }}
                  onError={() => {
                    this.animLogoEnded = true;
                  }}>
                  {brand.name}
                </HSpriteAnimation>
                {isJA && (
                  <span staticClass="my-head__anim-logo__label">
                    {this.hotelOnlyName}
                  </span>
                )}
              </span>
            )}
            {/* 界以外の場合 */}
            {!isKai && this.headLogoUrl && (
              <img
                staticClass="my-head__logo__img"
                class={{
                  'my-head__logo__img--loaded': this.headLogoLoaded,
                }}
                src={this.headLogoUrl}
                alt={hotel.fullName}
              />
            )}
            {!!logoAppends && (
              <span
                staticClass="my-head__logo__appends"
                class={{
                  'my-head__logo__appends--long': logoAppends.isLong,
                }}>
                {logoAppends.message}
              </span>
            )}
          </h1>
          {/* 界 */}
          {isKai && (
            <div staticClass="my-head__lead">
              {!isJA && (
                <h1 staticClass="my-head__lead__hotel-name">{hotel.name}</h1>
              )}
              <HHtmlInjector html={this.leadHTML} />
            </div>
          )}
        </div>

        {galleries.length > 0 && photogalleryBtn}
      </div>,
      // @MEMO この部分は共通化できそう
      <div staticClass="my-body">
        <HHtmlInjector staticClass="my-lead" html={this.leadHTML} />
        <MyHotelLocalNavi />
        <div staticClass="my-sections">
          {sections.map((section) => {
            return <MyHotelSection staticClass="my-section" data={section} />;
          })}
        </div>
      </div>,
    ];

    // 星のやの場合
    if (isHoshinoya) {
      childrens = [
        <div staticClass="my-bg my-bg--first-view"></div>,
        <div staticClass="my-bg my-bg--feature">
          {/* @TODO 時間でふわっと切り替え */}
          <div staticClass="my-bg--feature__img1"></div>
          <div staticClass="my-bg--feature__img2"></div>
          <div staticClass="my-bg--feature__img3"></div>
        </div>,
        <MyHotelLocalNavi />,
        <div
          staticClass="my-head"
          v-inview={{
            in: () => {
              this.hotelViewRoot.pageTopScrollActiveSectionId = 'first-view';
            },
            // @TODO 初期表示で縦幅が少ないと発火しないので対応必要
            rootMargin: '-100px',
          }}>
          <div staticClass="my-head__inner">
            <h1
              staticClass="my-head__logo"
              class={{
                'my-head__logo--has-appends': !!logoAppends,
              }}>
              <img
                staticClass="my-head__logo__img"
                class={{
                  'my-head__logo__img--loaded': this.headLogoLoaded,
                }}
                src={this.headLogoUrl}
                alt={hotel.fullName}
              />
              {!!logoAppends && (
                <span
                  staticClass="my-head__logo__appends"
                  class={{
                    'my-head__logo__appends--long': logoAppends.isLong,
                  }}>
                  {logoAppends.message}
                </span>
              )}
            </h1>
            <div class="my-head__logo__key_visual__wrapper">
              <HKeyVisual
                class="my-head__logo__key_visual"
                value={hotel.keyVisual}
                paused={needPauseAnimations}
                leadHTML={Boolean(this.leadHTML)}
              />
              <div class="my-head__logo__key_visual__shutter-up"></div>
              <div class="my-head__logo__key_visual__shutter-down"></div>
            </div>
          </div>

          {galleries.length > 0 && photogalleryBtn}

          <HHtmlInjector staticClass="my-lead" html={this.leadHTML} />
        </div>,

        <div staticClass="my-body">
          <div staticClass="my-sections">
            {sections.map((section) => {
              const separatorIcon =
                section.id !== 'feature'
                  ? this.$res.img(`hotels/${hotel.slug}/section-logo-white.svg`)
                  : undefined;

              return (
                <MyHotelSection
                  staticClass="my-section"
                  data={section}
                  separatorIcon={separatorIcon}
                />
              );
            })}
          </div>
        </div>,
      ];
    }

    // LUCYの場合
    if (isLucy) {
      const mainKeyvisualImage = hotel.keyVisual.image;
      const subKeyvisualImages = hotel.keyVisual.subImages;

      childrens = [
        <MyHotelLocalNavi />,
        <div staticClass="my-bg" />,
        <div staticClass="my-head">
          {subKeyvisualImages && subKeyvisualImages[0] && (
            <MyHotelInstantPhoto
              class="my-head__kv-photo-1"
              src={subKeyvisualImages[0].src}
              axis="horizontal"
              position={subKeyvisualImages[0].position}
            />
          )}

          {mainKeyvisualImage && (
            <MyHotelInstantPhoto
              class="my-head__kv-photo-2"
              src={mainKeyvisualImage}
              axis="horizontal"
              stackTiltAngle={-7}
              position={hotel.keyVisual.position}
            />
          )}

          {subKeyvisualImages && subKeyvisualImages[1] && (
            <MyHotelInstantPhoto
              class="my-head__kv-photo-3"
              src={subKeyvisualImages[1].src}
              axis="horizontal"
              position={subKeyvisualImages[1].position}
            />
          )}

          <div staticClass="my-head__inner">
            <h1
              staticClass="my-head__logo"
              class={{
                'my-head__logo--has-appends': !!logoAppends,
              }}>
              {this.headLogoUrl && (
                <img
                  staticClass="my-head__logo__img"
                  class={{
                    'my-head__logo__img--loaded': this.headLogoLoaded,
                  }}
                  src={this.headLogoUrl}
                  alt={hotel.fullName}
                />
              )}
            </h1>
            <a staticClass="my-head__see-map" href="#access">
              <img
                staticClass="my-head__see-map__icon"
                src={this.$res.img('/brands/lucy/icon-pin.svg')}
                alt="アイコン"
              />
              マップを見る
            </a>
            <HHtmlInjector staticClass="my-lead" html={this.leadHTML} />
            {!!logoAppends && (
              <span
                staticClass="my-head__logo__appends"
                class={{
                  'my-head__logo__appends--long': logoAppends.isLong,
                }}>
                {logoAppends.message}
              </span>
            )}
            {hotel.additionalData && (
              <MyHotelAdditionalData
                staticClass="my-head__additional-data"
                items={hotel.additionalData}
              />
            )}
          </div>
        </div>,
        <div>{galleries.length !== 0 && photogalleryBtn}</div>,
        <div staticClass="my-body">
          <div staticClass="my-sections">
            {sections.map((section) => {
              // 区切りアイコンをスキップするセクション一覧
              const skipSeparatorSections = ['feature', 'flow-line'];

              const separatorIcon =
                separatorIconsIter &&
                !skipSeparatorSections.includes(section.id)
                  ? (separatorIconsIter.next().value as string)
                  : undefined;

              return (
                <MyHotelSection
                  staticClass="my-section"
                  data={section}
                  separatorIcon={separatorIcon}
                />
              );
            })}
          </div>
        </div>,
      ];
    }

    return (
      <div
        key={hotel.id || ''}
        staticClass="my-hotel-index-view"
        class={this.classes}>
        {childrens}
      </div>
    );
  },
})
export default class MyView extends Vue {
  readonly hotelViewRoot!: HotelViewRoot;

  USE_MOTION_LOGO!: boolean;
  private animLogoEnded: boolean = false;
  private headLogoLoaded: boolean = false;
  private topHoshinoyaDuringAnimation: boolean = true;
  /**
   * イントロアニメーションのタイムライン
   * - イントロアニメーション実行中のみ存在します
   */
  private _introAnimationTimeline?: gsap.core.Timeline;

  get isKai() {
    return this.$theme.is('kai');
  }

  get isHoshinoya() {
    return this.$theme.is('hoshinoya');
  }

  get isLucy() {
    return this.$theme.is('lucy');
  }

  get preloadImages() {
    const images: string[] = [];
    const { keyVisualImage, kaiAnimLogo, keyVisualVideo } = this;
    !keyVisualVideo && keyVisualImage && images.push(keyVisualImage);
    kaiAnimLogo && images.push(kaiAnimLogo);
    return images;
  }

  get kaiAnimLogo() {
    return this.isKai ? this.$res.webp(`/brands/kai/logo-anim-sprite.png`) : '';
  }

  get leadHtmlInfo() {
    const { hotel } = this;
    const info: {
      html: string;
      portals: MyHotelSectionPortal[];
    } = {
      html: '',
      portals: [],
    };
    if (!hotel) return info;
    const { leadHTML, leadHTMLDefault } = hotel;
    info.html = (leadHTMLDefault || '') + leadHTML;
    const portalMatches = info.html.match(PORTALS_MATCH_RE);
    if (portalMatches) {
      portalMatches.forEach((portalMatch) => {
        const tmp = portalMatch.match(PORTAL_MATCH_RE);
        if (!tmp) return;
        const source = tmp[0];
        let target = tmp[1];
        target =
          target === 'first' ? this.hotelViewRoot.topSectionId() : target;
        const className = tmp[3];
        const isCopy = className && className.includes('h-portal-copy');
        const html = tmp[4].trim();
        info.portals.push({
          target,
          html,
          className,
        });
        info.html = info.html.replace(source, isCopy ? html : '');
      });
    }
    return info;
  }

  get leadHTML() {
    return this.leadHtmlInfo.html;
  }

  /** 青森屋と星のやは白背景のロゴを適用する */
  get headLogoUrl() {
    if (
      this.$theme.current.name === 'aomoriya' ||
      this.$theme.current.name === 'hoshinoya'
    ) {
      return this.$res.img(this.hotel.logo.stackInvert);
    } else {
      return this.$res.img(this.hotel.logo.stack);
    }
  }

  get keyVisualImage() {
    const { keyVisual } = this.hotel;
    return keyVisual && keyVisual.image && this.$res.img(keyVisual.image);
  }

  get keyVisualVideo() {
    const { keyVisual } = this.hotel;
    return keyVisual && keyVisual.video;
  }

  get logoAppends() {
    const message = this.hotel.bannerNotice;
    if (!message) return;
    const length = widthedStringLength(message);
    const isLong = length >= 20;
    return {
      message,
      isLong,
    };
  }

  get needPauseAnimations() {
    return this.hotelViewRoot.needPauseAnimations;
  }

  get hotel() {
    return this.hotelViewRoot.hotel;
  }

  get hotelSlug(): string {
    return this.hotel.slug;
  }

  get brand() {
    return this.hotel.brand;
  }

  get hotelOnlyName() {
    const { hotel, brand } = this;
    return hotel.name.replace(brand.name, '').trim();
  }

  get navigationStack() {
    return this.hotelViewRoot.navigationStack;
  }

  get classes() {
    const { animLogoEnded } = this;
    const scrollActiveSectionId = this.hotelViewRoot
      .pageTopScrollActiveSectionId;
    return {
      'my-hotel-index-view--anim-logo-ended': animLogoEnded,
      'my-hotel-index-view--anim-logo-not-ended': !animLogoEnded,
      [`my-hotel-index-view--hoshinoya--${scrollActiveSectionId}`]: scrollActiveSectionId,
      'my-hotel-index-view--hoshinoya--top-during-animation': this
        .topHoshinoyaDuringAnimation,
      'my-hotel-index-view--hoshinoya': this.isHoshinoya,
    };
  }

  get sections(): MyHotelSectionData[] {
    const keys = this.navigationStack.local!.map((nav) => nav.key);

    const { local: menus } = this.navigationStack;
    if (!menus) {
      throw new Error(`missing local navigationStack`);
    }

    const theme = this.$theme.current;
    const themeSctions = theme.sections;
    const { portals } = this.leadHtmlInfo;
    const sections: MyHotelSectionData[] = [];

    const { hasFlowLineSettings } = this.hotelViewRoot;

    keys.forEach((id) => {
      if (FLOW_LINE_SETTINGS_CONTENTS.includes(id)) {
        return;
      }

      const themeSection = themeSctions.find((m) => m.key === id);
      if (!themeSection) {
        throw new Error(`missing theme section at ${id}`);
      }
      const fullWidth = id === 'photogallery';
      const plain = id === 'todo';
      const portal = portals.find((p) => p.target === id);
      let label = themeSection.longLabel || themeSection.label;
      if (label) {
        label = label.replace(/<wbr(\s\/)?>/g, '<br />');
        label = label.replace('{{hotelName}}', this.hotel.name);
      }

      sections.push({
        id,
        label,
        headRotate: 0,
        fullWidth,
        plain,
        portal,
      });
    });

    const featureIndex = sections.findIndex(
      (section) => section.id === 'feature',
    );
    const guestroomIndex = sections.findIndex(
      (section) => section.id === 'guestroom',
    );
    const isGuestroom = guestroomIndex === 1;
    const isHoshinoyaTheme = this.$theme.current.name === 'hoshinoya';
    const isLucyTheme = this.$theme.current.name === 'lucy';
    const flowLineIndexForHoshinoya = guestroomIndex + 1;
    const flowLineIndex = featureIndex + 1;

    // LUCYテーマの場合は、常に6つのプロミスセクションを追加
    if (isLucyTheme) {
      const lucyPromisesSection = {
        id: 'lucy-promises',
        label: null,
        headRotate: 0,
        fullWidth: true,
        plain: true,
      };

      // プロミスセクションはfeatureの後に追加する
      sections.splice(flowLineIndex, 0, lucyPromisesSection);
    }

    // TOP導線（flow-line）は3つのおすすめ（feature）の後に表示。3つのおすすめがあればその次に入れる、なければ先頭に入れる。
    if (hasFlowLineSettings) {
      const flowLineSection = {
        id: 'flow-line',
        label: null,
        headRotate: 0,
        fullWidth: true,
        plain: true,
      };

      /**
       * 導線セクションを挿入する場所
       *
       * * 3つのオススメがあればその次
       * * 3つのおすすめがなければ先頭
       * * 星のやの場合は、ゲストルームがあればその次、ゲストルームがなければ他に準ずる
       * * LUCYの場合は、すでに追加した6つのプロミスの後に導線セクションを追加
       */
      if (isHoshinoyaTheme && isGuestroom) {
        sections.splice(flowLineIndexForHoshinoya, 0, flowLineSection);
      } else if (isLucyTheme) {
        // LUCYの場合、すでに追加したlucyPromisesSectionの後に追加する
        const lucyPromisesIndex = sections.findIndex(
          (section) => section.id === 'lucy-promises',
        );
        sections.splice(lucyPromisesIndex + 1, 0, flowLineSection);
      } else {
        sections.splice(flowLineIndex, 0, flowLineSection);
      }
    }

    let indexForHasHeader = 0;
    return sections.map((section) => {
      if (section.plain) {
        return section;
      }
      indexForHasHeader++;
      return { ...section, evenSection: indexForHasHeader % 2 === 0 };
    });
  }

  /**
   * セクション区切りアイコン
   */
  private sectionSeparatorIconsIter() {
    const { sectionIcons } = this.hotel;
    return sectionIcons ? sectionIcons[Symbol.iterator]() : undefined;
  }

  /**
   * 表示言語によるリダイレクト処理
   * 動作は、
   * 枠外をクリックしたとき => 何もしない
   * OKを押したとき => cookieにbrowserLanguageが保存され、browserLanguageの言語表示のページへリダイレクトする
   * キャンセルを押したとき => 現在表示されているページの言語情報がcookieに保存される
   */
  private async confirmLanguageRedirect(browserLanguage: AvailableLanguage) {
    try {
      const result = await this.$confirm({
        content: text[browserLanguage].guide,
        actions: [
          {
            type: 'cancel',
            text: text[browserLanguage].cancel,
            color: 'secondary',
            click: (dialog) => {
              dialog.resolve(false);
            },
          },
          {
            type: 'ok',
            text: text[browserLanguage].ok,
            color: 'primary',
            click: (dialog) => {
              dialog.resolve(true);
            },
          },
        ],
      });

      // 枠外をクリックするとtrueになる
      if (result === undefined) return;

      if (result) {
        const currentPath = this.$route.fullPath;
        const newPath = currentPath.replace(
          `/${this.$language.current}/`,
          `/${browserLanguage}/`,
        );
        // 言語情報をcookieに保存する
        this.$language.saveCookie(browserLanguage as AvailableLanguage);
        window.location.href = newPath;
      } else {
        this.$language.saveCookie(this.$language.current as AvailableLanguage);
      }
    } catch (_err) {
      this.$error.throw(_err);
    }
  }

  /** イントロアニメーション破棄 */
  private killIntroAnimationTimeline() {
    if (this._introAnimationTimeline) {
      this._introAnimationTimeline.kill();
      this._introAnimationTimeline = undefined;
    }
  }

  /** イントロアニメーション開始 */
  private dispatchIntroAnimation() {
    this.killIntroAnimationTimeline();

    // 星のや
    if (this.isHoshinoya) {
      const timeline = gsap
        .timeline()
        .to('.my-head__logo', {
          delay: 1.5,
          opacity: 1,
          duration: 0.5,
          ease: 'Expo.easeInOut',
        })
        .to(
          '.my-head__logo',
          {
            delay: 1.5,
            y: 0,
            duration: 1.5,
            ease: 'Expo.easeInOut',
          },
          'openTiming',
        )
        .to(
          '.my-head__logo__key_visual__shutter-up',
          {
            delay: 2.5,
            scaleY: 0,
            duration: 1.5,
            ease: 'Expo.easeInOut',
          },
          'openTiming',
        )
        .to(
          '.my-head__logo__key_visual__shutter-down',
          {
            delay: 2.5,
            scaleY: 0,
            duration: 1.5,
            ease: 'Expo.easeInOut',
          },
          'openTiming',
        );
      this._introAnimationTimeline = timeline;

      timeline.then(() => {
        this.killIntroAnimationTimeline();
      });
    }

    // LUCY
    if (this.isLucy) {
      const animation = () => {
        const timeline = gsap.timeline();

        const commonParams: gsap.TweenVars = {
          duration: 1.3,
          ease: 'Expo.easeInOut',
        };

        const objectFadeIn: gsap.TweenVars = {
          opacity: 1,
          y: 0,
          duration: 0.4,
          ease: 'Power1.out',
        };

        // 紙を上にスライド
        timeline.to('.my-bg', {
          y: '-100vh',
          ...commonParams,
        });

        // 同時に背景を上にスライド
        timeline.to(
          ':root',
          {
            '--bg-grid-paper-position': 0,
            ...commonParams,
          },
          '<',
        );

        // 同時にページのコンテンツを上にスライド
        // NOTE:
        // my-head__inner だけをスライドさせると、アンカーリンク付きで遷移してきたときに要素が重なって見えてしまう
        // なので、全部スライドさせて自然に見えるようにしています
        timeline.to(
          ['.my-head__inner', '.my-body'],
          {
            y: 0,
            ...commonParams,
          },
          '<',
        );

        // アニメーション終了後、写真の下に重なっている部分を上にフェードイン
        timeline.to(
          '.my-head__kv-photo-2',
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: 'Power1.out',
          },
          '>',
        );

        // 同時に写真の上に重なっている部分を下にフェードイン
        timeline.to(
          '.my-head__kv-photo-2 > .my-hotel-instant-photo__inner',
          objectFadeIn,
          '<+0.1',
        );

        // アニメーション終了後、順に小物をフェードイン
        timeline.to('.my-head__kv-photo-1', objectFadeIn, '<+0.1');
        timeline.to('.my-head__kv-photo-3', objectFadeIn, '<+0.1');
        timeline.to('.my-head__logo', objectFadeIn, '>+0.2');

        this._introAnimationTimeline = timeline;

        timeline.then(() => {
          this.killIntroAnimationTimeline();
        });
      };

      // SPでページ読み込み後すぐにアニメーションを再生すると、かなり重たくなるので少し遅らせる
      setTimeout(() => animation(), 1000);
    }
  }
}
